<template>
    <div class="tribe-overview">
        <div class="row">
            <div class="container">
                <section class="section-tribe">
                    <div class="col">
                        <div class="row-tribe">
                            <div class="container-tribe row">
                                <div class="block col">
                                    <div class="icon icon-level"></div>
                                    <span class="title">My power level</span>
                                    <span class="value">Lvl {{ (userData && userData.userRefData && userData.userRefData?.level) || 0 }}</span>
                                </div>
                                <div class="block row">
                                    <div class="col">
                                        <div class="icon icon-rewards"></div>
                                        <span class="title">Claimable tribal rewards</span>
                                        <span class="value icon-value"> <i class="coin-icon"></i>{{ getClaimableAmount }} </span>
                                    </div>
                                    <div class="col">
                                        <button @click="claimReferrerReward()" class="btn-claimed">Claim</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-tribe">
                            <div class="container-tribe col">
                                <div class="row">
                                    <div class="block col">
                                        <div class="icon icon-amount"></div>
                                        <span class="title">Cumulative # of elves summoned</span>
                                        <span class="value">{{ (userData && userData.userRefData && userData.userRefData.childrens.length) || "0" }}</span>
                                    </div>
                                    <div class="block col">
                                        <div class="icon icon-sales"></div>
                                        <span class="title">Cumulative sales</span>
                                        <span class="value icon-value">
                                            <i class="coin-icon"></i
                                            >{{ userData && userData.sales ? parseFloat(Number(userData.sales).toFixed(4)) : "0.00" }}</span
                                        >
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="block col">
                                        <div class="icon icon-claimed"></div>
                                        <span class="title">Cumulative tribal rewards claimed</span>
                                        <span class="value icon-value">
                                            <i class="coin-icon"></i
                                            >{{
                                                userData && userData?.referrerRewardClaimed
                                                    ? parseFloat(Number(userData?.referrerRewardClaimed).toFixed(4))
                                                    : "0.00"
                                            }}</span
                                        >
                                    </div>
                                    <!-- <div class="col"> -->
                                    <!-- <button class="btn-claimed">Claim</button> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="row">
            <div class="container">
                <section class="section-tribe row">
                    <div class="col col-link">
                        <div class="title">Summon elves</div>
                        <div class="link">
                            <input type="text" :placeholder="getReferralLink" disabled />
                            <button
                                v-if="userData && Number(userData.totalPositionAmount) > 0"
                                @click="copyToClipboard(getReferralLink, 'Referral Link')"
                                class="btn btn-copy"
                            >
                                Copy
                            </button>
                            <button v-else @click="copyToClipboard('', 'Referral Link')" class="btn btn-copy">Copy</button>
                        </div>
                    </div>

                    <div class="col col-social">
                        <div class="title">Quick summon</div>
                        <div class="row social-links-wrap">
                            <a href="#" class="social-link twitter"> </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <section class="section-content">
            <div class="container">
                <div class="container-s">
                    <div class="col">
                        <div class="h2">What are tribal rewards?</div>
                        <p class="p">After the tribe is formed, tribe members will receive tribal rewards for every seed gold they participate in!</p>
                        <div class="btn btn-content">Check my tribe's details</div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-content">
            <div class="container">
                <div class="container-s">
                    <div class="col">
                        <div class="h2">How to form a tribe to and tribal rewards</div>
                        <p class="p">
                            Come to the planet Pandora, open your magic box, and summon your elves. Promote to tribe leader, pass the identity seed, summon more
                            elves, and form a tribe. Tribal rewards will be automatically assigned to your identity address. The more elves you have in your
                            tribe, the more powerful you are, and the higher the tribal rewards you will receive!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Config from "../../../config.json";
    var conf = Config[Config.env];
    import { mapState } from "vuex";
    import copyMixin from "../../core/copyMixin";
    export default {
        mixins: [copyMixin],
        name: "Overview",
        computed: {
            ...mapState(["userData", "currentAddress"]),
            getClaimableAmount() {
                if (!this.userData) return "0.00";
                const { referrerRewardClaimed, totalReferrerReward } = this.userData;
                return parseFloat((Number(totalReferrerReward) - Number(referrerRewardClaimed)).toFixed(4));
            },
            getReferralLink() {
                if (!this.userData || Number(this.userData.totalPositionAmount) === 0) {
                    return `${conf.REF_BASE}/?ref...`;
                }
                return `${conf.REF_BASE}/?ref=${this.currentAddress}`;
            },
        },
        methods: {
            async claimReferrerReward() {
                try {
                    let tx = await this.$root.core.claimReferrerReward(this.currentAddress);
                    this.$store.commit("push_notification", {
                        type: "warning",
                        typeClass: "warning",
                        message: `Processing your transaction...`,
                    });
                    await tx.wait(3);
                    this.$store.commit("push_notification", {
                        type: "success",
                        typeClass: "success",
                        message: `Your transaction has been completed.`,
                    });
                } catch (error) {
                    console.log(error);
                    this.$root.core.handleError(error);
                }
            },
        },
    };
</script>
