import { mapState } from "vuex";
import config from "../../config.json";
var conf = config[config.env];
export default {
    name: "copiedMixin",
    data: function () {
        return {
            buttonText: "Copy Link",
            successClass: false,
            hasDeposits: false,
        };
    },
    computed: {
        ...mapState(["userData", "currentBlockchain"]),
    },
    methods: {
        copyToClipboard(str, type) {
            console.log(str);
            const el = document.createElement("textarea");
            el.addEventListener("focusin", (e) => e.stopPropagation());
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.copySuccess(type);
        },
        copySuccess(type) {
            if (type === "Referral Link" && Number(this.userData.totalPositionAmount) === 0) {
                this.$store.commit("push_notification", {
                    type: "warning",
                    typeClass: "warning",
                    message: `Referral link is not available. Please make an investment.`,
                });
                return;
            }

            this.$store.commit("push_notification", {
                type: "success",
                typeClass: "success",
                message: `${type} has been copied!`,
            });
        },
        cutOffDecimals(number, roundTo = 2) {
            if (roundTo === 2) {
                if (number.toString().indexOf(".") !== -1) {
                    const splittedNumber = number.toString().split(".");
                    splittedNumber[1] += "00";
                    number = splittedNumber.join(".");

                    return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                } else {
                    number = number.toString() + ".00";
                    return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                }
            } else if (roundTo === 4) {
                if (number.toString().indexOf(".") !== -1) {
                    const splittedNumber = number.toString().split(".");
                    splittedNumber[1] += "00";
                    number = splittedNumber.join(".");

                    return number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
                } else {
                    number = number.toString() + ".00";
                    return number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
                }
            }
        },
        copyFailed() {
            alert("error");
        },
    },
};
